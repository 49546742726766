import React, { ReactNode } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ProtectedRoute from "./utils/ProtectedRoute";

// Lazy Load component
const Home = React.lazy(() => import("./pages/homePage/Home"));
const Products = React.lazy(() => import("./pages/Products"));
const Collection = React.lazy(() => import("./pages/collection/Collection"));
const MqTestKit = React.lazy(() => import("./pages/MqTestKit"));
const Profile = React.lazy(() => import("./pages/profile/Profile"));
const MyHorses = React.lazy(() => import("./pages/dashboard/landing/MyHorses"));
const AddHorse = React.lazy(() => import("./pages/dashboard/landing/AddHorse"));
const AssignKit = React.lazy(
  () => import("./pages/dashboard/assign-kit/AssignKit")
);
const Report = React.lazy(() => import("./pages/dashboard/reports/Landing"));
const HealthSurvey = React.lazy(
  () => import("./pages/dashboard/healthSurvey/Landing")
);
const ProductDetails = React.lazy(
  () => import("./pages/productDetail/ProductDetails")
);
const Team = React.lazy(() => import("./pages/team/Team"));
const WhatIsAMicrobiome = React.lazy(
  () => import("./pages/sciencePage/WhatIsAMicrobiome")
);
const SeeTheScience = React.lazy(
  () => import("./pages/sciencePage/SeeTheScience")
);
const Cart = React.lazy(() => import("./pages/cart/cartLanding"));
const ContactUS = React.lazy(() => import("./pages/contactUs/ContactUs"));
const TermsAndConditions = React.lazy(
  () => import("./pages/TermsAndConditions")
);
const ReportAdmin = React.lazy(() => import("./pages/dashboard/reportAdmin/ReportsAdmin"));
const Ckeditor = React.lazy(() => import("./pages/Ckeditor"));
const AuthHandler = React.lazy(() => import("./pages/AuthHandler"))
const PageNotFound = React.lazy(() => import("./pages/PageNotFound"));
const Prohibited = React.lazy(() => import("./pages/ProhibitedPage"));
const Faqs= React.lazy(()=> import("./pages/faq/Faqs"));
const ExternalRedirect=React.lazy(() => import("./components/re-usable/ExternalRedirect "))

export type TRoute = {
  component: ReactNode;
  exact: boolean | undefined;
  name: string | undefined;
  path: string;
  routes?: TRoute[] | undefined;
};

const AppRouter: TRoute[] = [
  { path: "/", exact: true, name: "Home", component: <Home /> },
  {
    path: "/collection",
    exact: true,
    name: "Collection",
    component: <Collection />,
  },
  {
    path: "/mq-test-kit",
    exact: true,
    name: "mq-test-kit",
    component: <ProtectedRoute component={<MqTestKit />} />,
  },
  {
    path: "/profile",
    exact: true,
    name: "Profile",
    component: <ProtectedRoute component={<Profile />} />,
  },
  {
    path: "/my-stable",
    exact: true,
    name: "Dashboard",
    component: <ProtectedRoute component={<MyHorses/>} />
  },
  {
    path: "/my-stable/add",
    exact: true,
    name: "AddHorse",
    component: <ProtectedRoute component={<AddHorse />} />,
  },
  {
    path: "/my-stable/:horseGuid",
    exact: true,
    name: "AddHorse",
    component: <ProtectedRoute component={<AddHorse />} />,
  },
  {
    path: "/my-stable/:horseGuid/assign-kit",
    exact: true,
    name: "AddHorse",
    component: <ProtectedRoute component={<AssignKit/>} />
  },
  {
    path: "/report/:kitID",
    exact: true,
    name: "Report",
    component: <ProtectedRoute component={<Report />} />,
  },
  {
    path: "/my-stable/:horseID/health-survey/:kitID",
    exact: true,
    name: "HealthSurvey",
    component: <ProtectedRoute component={<HealthSurvey/>} />
  },
  // {
  //   path: "/product-details",
  //   exact: true,
  //   name: "ProductDetails",
  //   component: <ProductDetails />,
  // },
  {
    path: "/product-details",
    exact: true,
    name: "ProductDetailssystemiq",
    component: <Navigate to="/product/systemiq" replace />,
  },
  {
    path: "/product/:productName",
    exact: true,
    name: "ProductDetails",
    component: <ProductDetails />,
  },
  {
    path: "/team",
    exact: true,
    name: "Team",
    component: <Team />,
  },
  {
    path: "/what-is-a-microbiome",
    exact: true,
    name: "what is a microbiome",
    component: <WhatIsAMicrobiome />,
  },
  {
    path: "/see-the-science",
    exact: true,
    name: "see the science",
    component: <SeeTheScience />,
  },
  {
    path: "/cart",
    exact: true,
    name: "Cart",
    component: <Cart />,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    name: "Terms And Conditions",
    component: <TermsAndConditions />,
  },
  {
    path: "/contact-us",
    exact: true,
    name: "Contact Us",
    component: <ContactUS />,
  },
  {
    path: "/ckeditor",
    exact: true,
    name: "Ckeditor",
    component: <Ckeditor />,
  },
  {
    path: "/reports-admin",
    exact: true,
    name: "Reports Admin",
    component: <ProtectedRoute component={<ReportAdmin />} />
  },
  {
    path: "/account/login",
    exact: true,
    name: "Auth Handler",
    component: <AuthHandler />,
  },
  {
    path: "/frequently-asked-questions",
    exact: true,
    name: "Faqs",
    component: <Faqs />,
  },
    {
    path: "/mqvet24",
    exact: true,
    name: "mqvet24",
    component: <ExternalRedirect reditectUrl="https://shop.purinaequinemq.com/discount/mqvet24?redirect=/cart/47934829429036:1,47934829625644:1" />,
  },
  {
    path: "/prohibited",
    exact: true,
    name: "Prohibited",
    component: <Prohibited />,
  },
  {
    path: "*",
    exact: true,
    name: "Page Not Found",
    component: <PageNotFound />,
  },
];

export default AppRouter;
