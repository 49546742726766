import thunkMiddleware from 'redux-thunk';
import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';


import ProductsPOCReducer from './slice/ProductsSlice';
import CheckoutReducer from './slice/CheckoutSlice';
import CounterReducer from './slice/CounterSlice';
// Protected router
import ProtectedRouterReducer from './slice/ProtectedRouterSlice';
// Global
import NavigationReducer, { CartValuesReducer } from './slice/NavigationSlice';
import PageConfigurationsReducer from './slice/PageConfigurationsSlice';
import GetAllTermsAndConditionsReducer from './slice/TermsAndConditionsSlice';
import PageNotFoundReducer from "./slice/PageNotFoundSlice";
import ProhibitedReducer from "./slice/ProhibitedSlice";

// Home
import HeroBannerReducer from './slice/home/HeroBannerSlice';
import IntroReducer from './slice/home/IntroSlice';
import MeetMQReducer from './slice/home/MeetMQSlice';
import SupplementReducer from './slice/home/SupplementSlice';
import HowItWorksReducer from './slice/home/HowItWorksSlice';
import ScinceReducer from './slice/home/ScienceSlice';
import PurinaTeamReducer from './slice/home/PurinaTeamSlice';
import ProductsReducer from './slice/home/ProductsSlice';
import HelpReducer from './slice/home/HelpSlice';

// Customer Info
import UpdateCustomerReducer from './slice/customer-info/UpdateCustomerSlice';
import {
  AddCustomerAddressReducer,
  UpdateCustomerAddressReducer,
  SetCustomerAddressReducer,
  GetAllCustomerAddressReducer,
  DeleteCustomerAddressReducer,
  GetCustomerInfoByIdReducer,
} from './slice/customer-info/CustomerAddressSlice';

// Dashboard
import GetAllMyHorsesReducer from './slice/dashboard/GetAllMyHorsesSlice';
import GetAllAddsCardReducer from './slice/dashboard/AddsCardSlice';
import HorseBreedReducer from './slice/dashboard/horseBreedSlice';
import HorseAgeReducer from "./slice/dashboard/horseAgeSlice";
import HorseGenderReducer from './slice/dashboard/horseGenderSlice';
import HorseLocationReducer from './slice/dashboard/horseLocationSlice';
import PostAddHorseReducer from './slice/dashboard/CreateHorseSlice';
import AssignKitReducer from './slice/dashboard/AssignKitSlice';
import HorseKitInfoReducer from './slice/dashboard/HorseKitInfoByIDSlice';
import { GetEditHorseReducer, UpdateHorseReducer } from './slice/dashboard/CreateHorseSlice';
import SampleMailedReducer from './slice/dashboard/SampleMailedSlice';

// Health Survey
import GetAllHorseHealthHistoryReducer from './slice/healthSurvey/HorseHealthHistorySlice';
import GetAllHorseLifeStyleReducer from './slice/healthSurvey/HorseLifeStyleSlice';
import GetAllBodyConditionScoreReducer from './slice/healthSurvey/HorseBodyConditionScoreSlice';
import AddHorseHealthSurveyReducer from "./slice/healthSurvey/HorseHealthSurvey";
import getHorseHealthSurveyByKitId from "./slice/healthSurvey/HorseHealthSurveyByKitIdSlice";

// team
import GetTeamReducer from "./slice/TeamSlice";
// Science
import { GetScienceDiscoveryReducer, GetScienceReducer } from './slice/ScienceSlice';

// Product
import ProductDetailsReducer from './slice/product/ProductDetailSlice';

import ProductsMqReducer from './slice/product/ProductsSlice';

// Reports
import ReportStatusAndResultReducer, { ReportChartsReducer, ReportCategoryAndGroupReducer, ReportCompareChartReducer, RationCheckerReducer, ReportsPrintReducer } from './slice/reports/ReportsSlice'

// Contact Us
import { GetContactUsReducer, SubmitContactUsReducer } from "./slice/ContactUsSlice";
// Admin
import { AdminReducer, ApproveReportReducer, CheckUserAdminStatusReducer, KitStatusCountReducer, SaveNotesReducer } from './slice/reports/admin/ReportsAdminSlice';
import { CreateCartReducer, GetCartReducer } from './slice/cart/cartSlice';
import AdobeReducer from './slice/adobe/adobeSlice';
import ContentReducer from './slice/Content/ContentSlice'
import InstructionalVideoReducer from './slice/InstructionalVideo/InstructionalVideoSlice'

const reducers = {
  ProductsPOC: ProductsPOCReducer,
  Checkout: CheckoutReducer,
  Counter: CounterReducer,

  // Authrization
  ProtectedRouter: ProtectedRouterReducer,
  //Global
  Navigation: NavigationReducer,
  PageConfigurations: PageConfigurationsReducer,
  TermsAndConditions: GetAllTermsAndConditionsReducer,
  PageNotFound: PageNotFoundReducer,
  Prohibited: ProhibitedReducer,

  // Home
  HeroBanner: HeroBannerReducer,
  Intro: IntroReducer,
  MeetMQ: MeetMQReducer,
  Supplement: SupplementReducer,
  HowItWorks: HowItWorksReducer,
  ScinceHome: ScinceReducer,
  PurinaTeam: PurinaTeamReducer,
  Products: ProductsReducer,
  Help: HelpReducer,

  // Customer
  UpdateCustomer: UpdateCustomerReducer,
  AddCustomerAddress: AddCustomerAddressReducer,
  UpdateCustomerAddress: UpdateCustomerAddressReducer,
  SetCustomerAddress: SetCustomerAddressReducer,
  GetAllCustomerAddress: GetAllCustomerAddressReducer,
  DeleteCustomerAddress: DeleteCustomerAddressReducer,
  GetCustomerInfoById: GetCustomerInfoByIdReducer,

  // dashboard
  GetAllMyHorses: GetAllMyHorsesReducer,
  AddsCard: GetAllAddsCardReducer,
  horseBreed: HorseBreedReducer,
  horseAge: HorseAgeReducer,
  horseGender: HorseGenderReducer,
  horseLocation: HorseLocationReducer,
  PostAddHorse: PostAddHorseReducer,
  GetEditHorse: GetEditHorseReducer,
  UpdateMyHorse: UpdateHorseReducer,
  PostAssignKit: AssignKitReducer,
  HorseKitInfoList: HorseKitInfoReducer,
  SampleMailed: SampleMailedReducer,

  // Health Survey
  GetAllHorseHealthHistory: GetAllHorseHealthHistoryReducer,
  GetAllHorseLifeStyle: GetAllHorseLifeStyleReducer,
  GetAllBodyConditionScore: GetAllBodyConditionScoreReducer,
  CreateHorseHealthSurvey: AddHorseHealthSurveyReducer,
  getHorseHealthSurveyByKitId: getHorseHealthSurveyByKitId,

  // team
  GetTeam: GetTeamReducer,

  // Science
  GetScienceDiscovery: GetScienceDiscoveryReducer,
  GetScience: GetScienceReducer,

  // Product
  ProductDetails: ProductDetailsReducer,
  ProductsMq: ProductsMqReducer,
  CartValues: CartValuesReducer,

  // Reports
  ReportStatusAndResult: ReportStatusAndResultReducer,
  ReportCharts: ReportChartsReducer,
  ReportCategoryAndGroup: ReportCategoryAndGroupReducer,
  ReportCompareChart: ReportCompareChartReducer,
  RationChecker: RationCheckerReducer,
  ReportsPrint: ReportsPrintReducer,

  // Contact Us
  ContactUs: GetContactUsReducer,
  SubmitContactUs: SubmitContactUsReducer,

  //Admin
  CheckUserAdminStatus: CheckUserAdminStatusReducer,
  Admin: AdminReducer,
  SaveNotes: SaveNotesReducer,
  ApproveReport: ApproveReportReducer,
  KitStatusCount:KitStatusCountReducer,

  // Cart
  CreateCart: CreateCartReducer,
  GetCart: GetCartReducer,

  // adobe
  Adobe:AdobeReducer,
  Content:ContentReducer,
  InstructionalVideo:InstructionalVideoReducer
}


export const Store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['app/addToast'],
      },
    }).concat(thunkMiddleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof Store.getState>;
export type AppDispatch = typeof Store.dispatch;

// eslint-disable-next-line
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;

  // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
