import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {TInstructionalVideo,TInstructionalVideoInitialStateType} from '../../../models/Video/InstructionalVideoModel';
import {InstructionalVideoService} from '../../../services/InstructionalVideo/InstructionalVideoService'

const initialState: TInstructionalVideoInitialStateType = {
    InstructionalVideoData: { VideoUrl: "" },
    InstructionalVideoLoading: true,
    InstructionalVideoError: undefined,
  };

  export const retrieveInstructionalVideoThunk = createAsyncThunk(
    "InstructionalVideo/retrieve",
    async () => {
      const response = await InstructionalVideoService.GetVideoUrl
      console.log("video",response.data);
      return response.data;
    }
  );
  const InstructionalVideoSlice = createSlice({
    name: 'PageConfigurations',
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(retrieveInstructionalVideoThunk.pending, (state) => {
          state.InstructionalVideoLoading = true;
        })
        .addCase(retrieveInstructionalVideoThunk.fulfilled, (state, action) => {
          if(action.payload){
            state.InstructionalVideoLoading = false;
            state.InstructionalVideoData.VideoUrl = action.payload; // No need for array
          }
        })
        .addCase(retrieveInstructionalVideoThunk.rejected, (state, action) => {
          state.InstructionalVideoLoading = false;
        })
    },
  });

  export default InstructionalVideoSlice.reducer;